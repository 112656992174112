import {
  faUserCog,
  faLayerGroup,
  faDisplay,
} from "@fortawesome/free-solid-svg-icons";

const pagesSection = [
  {
    href: "/",
    icon: faLayerGroup,
    title: "Eventos",
    admin: false,
  },
  {
    href: "/aplicativos",
    icon: faDisplay,
    title: "Aplicativos",
    admin: false,
  },
  {
    href: "/usuarios",
    icon: faUserCog,
    title: "Usuários",
    admin: false,
  },
];

const DashboardItems = (state) => {
  let navItems = [{
    title: "Menu",
    pages: [{
      href: "/",
      icon: faLayerGroup,
      title: "Eventos"
    }],
  }]
  if (state.aplicativos_customizacao === 1) {
    navItems[0].pages.push({
      href: "/aplicativos",
      icon: faDisplay,
      title: "Aplicativos"
    })
  }
  if (state.tipo === 1) {
    navItems[0].pages.push({
      href: "/usuarios",
      icon: faUserCog,
      title: "Usuários"
    })
  }
  return navItems
}

export default DashboardItems;
