import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";

function SignIn() {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { signIn } = useAuth();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Email inválido")
          .required("Campo obrigatório"),
        password: Yup.string()
          .min("6", "Mínimo de 6 caracteres")
          .required("Campo obrigatório"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);
          navigate("/");
        } catch (error) {
          var type = "danger";
          var ripple = true;
          var dismissible = true;
          notyf.open({
            type,
            message: error,
            ripple,
            dismissible,
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form className="mt-3 p-2" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder="Digite seu email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Digite sua senha"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <p className="mt-2 mb-4 text-end text-dark">
              <Link to="/auth/reset-password">Esqueceu sua senha?</Link>
            </p>
          </Form.Group>
          <div className="text-center mt-2">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Entrar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
