
import React, {
    useEffect,
    useState,
    useContext
} from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import {
    Container,
    Button,
    Col,
    Breadcrumb,
    Card,
    Form,
    Row,
    OverlayTrigger, Tooltip
} from "react-bootstrap";
import { ColumnSortingTable } from "../../components/Table.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSync } from "@fortawesome/free-solid-svg-icons";
import { Trash, Download } from "react-feather";
import Loader from "../../components/Loader.js";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import
import NotyfContext from "../../contexts/NotyfContext.js";
import useAuth from "../../hooks/useAuth";
import { confirmAlert } from "react-confirm-alert";
import { ShowConfirm } from "../../components/Alerts.js";
import useAppDispatch from "../../hooks/useAppDispatch";
import useLayout from "../../hooks/useLayout.js";
import {
    eventList
} from "../../redux/slices/events";
import {
    SelectColumnFilter,
    generateDateCategories,
    NumberRangeColumnFilter,
    getExcelFromObject
} from "../../assets/functions.js";
import { statusX } from "../../components/Events.js";
import {
    setHeaders01
} from "../../components/EventHistory.js";
import { processData01, processData02, generateAccessData } from '../../components/EventHistory'

export const EventHistory01 = ({ dataTable, setShowHistory }) => {
    const [historyData, setHistoryData] = useState(false);
    const [updateDataTable, setDataTableUpdate] = useState(false);
    const [updateDataTableWarning, setDataTableUpdateWarning] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [triggerExportResult, setTriggerExportResult] = useState(false);
    const userState = useAuth();
    const { setLoading } = useLayout();
    const notyf = useContext(NotyfContext);
    const days = generateDateCategories()
    const [filter, setFilter] = useState({ customizacao_id: null });
    const [fields, setFields] = useState([]);
    const [fieldsResult, setFieldsResult] = useState([]);
    const dispatch = useAppDispatch();

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    svg: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    png: {
                        filename: dataTable.titulo + ' - Gráfico',
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow", // full, hollow, inverted
        },
        xaxis: {
            categories: days,
        },
        colors: [
            `#4ba700`
        ],
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    function handleDelete(props) {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <ShowConfirm
                        title="Confirmar ação"
                        description="Deseja remover esse participante? Todos os dados relacionados ao usuário serão excluídos."
                        action={() => {
                            deletParticipant(props, 1);
                            onClose();
                        }}
                        onClose={onClose}
                    />
                );
            }
        });
    }

    function deletParticipant(props, platform) {
        setLoading(true)
        axios
            .delete(`/api/history/${props}`, {
                data: { platform: platform }
            })
            .then(function (response) {
                setDataTableUpdate((prevCount) => prevCount + 1);
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    function deleteResult(props) {
        setLoading(true)
        axios
            .delete(`/api/customization/history/${props}`)
            .then(function (response) {
                setDataTableUpdate((prevCount) => prevCount + 1);
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    function handleDeleteResult(props, id) {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <ShowConfirm
                        title="Confirmar ação"
                        description={`Todos os resultados relacionados ao sorteio "${id}" serão excluídos. Deseja continuar?`}
                        action={() => {
                            deleteResult(props);
                            onClose();
                        }}
                        onClose={onClose}
                    />
                );
            },
        });
    }

    useEffect(() => {
        let getIndexApp = dataTable.aplicativos.findIndex(field => field.customizacao_id == filter.customizacao_id);
        let applicationType = getIndexApp >= 0 ? dataTable.aplicativos[getIndexApp].aplicativo_id : null
        axios.get(`/api/event/participants/${dataTable.evento_id}?platform=${dataTable.platform}&customizacao_id=${filter.customizacao_id}&type=${applicationType}`)
            .then(function (response) {
                let currentApp
                if (applicationType !== null && dataTable.aplicativos[getIndexApp]) {
                    currentApp = dataTable.aplicativos[getIndexApp].json
                }
                let headers = setHeaders01(dataTable.json.form.fields, setTriggerExport, handleDelete)
                setFields(headers)
                if (response.data.results && response.data.results.length > 0) {
                    setHeadersResult(setFieldsResult, setTriggerExportResult, handleDeleteResult)
                }
                let data = processData01(dataTable, response.data, currentApp, applicationType, setFields, setFieldsResult)
                setHistoryData(data);
                if (updateDataTableWarning === "update") {
                    setDataTableUpdateWarning(false);
                    notyf.open({
                        type: "success",
                        message: "Histórico atualizado com sucesso",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataTable, filter]);

    const data = generateAccessData(historyData.participants)

    return (
        <Container fluid className="p-0">
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setDataTableUpdate((prevCount) => prevCount + 1);
                    setDataTableUpdateWarning("update");
                    setLoading(true)
                }}
            >
                <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    if (userState.plataforma_aplicativos === 1) {
                        setShowHistory(false)
                    } else {
                        dispatch(eventList());
                    }
                }}
            >
                Voltar
            </Button>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        dispatch(eventList());
                    }}
                >
                    Eventos
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Editar evento
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Relatórios
                </Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted mb-3">
                Consulte os dados coletados durante o evento
            </h6>
            <Card>
                <Card.Body>
                    <p className="mb-0"><b>Evento: </b>{dataTable.titulo}</p>
                    <p className="mb-0"><b>Status: </b>{statusX[dataTable.status]}</p>
                    <p className="mb-0"><b>Duração: </b>{dataTable.data_criacao} até {dataTable.data_termino}</p>
                </Card.Body>
            </Card>
            {(historyData === false || historyData === undefined || fields.length === 0) ?
                (
                    <div className="py-5">
                        <Loader></Loader>
                    </div>
                ) : (
                    <>

                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">
                                Total de registros ({historyData.participants.length})</h5>
                        </Form.Group>
                        <Card>
                            <Card.Body>
                                <div className="chart">
                                    <Chart options={options} series={data} type="line" height="350" />
                                </div>
                            </Card.Body>
                        </Card>
                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">Lista de participantes</h5>
                        </Form.Group>
                        <Row className="mx-0">
                            <Form.Group
                                as={Col}
                                className={"mb-3"}
                                sm={"4"}
                                controlId="validationFormik03">
                                <Form.Label>Selecione um aplicativo</Form.Label>
                                <Form.Select
                                    value={filter.customizacao_id}
                                    onChange={(e) => {
                                        setFields([]);
                                        setFieldsResult([]);
                                        setFilter((prevState) => ({
                                            ...prevState,
                                            customizacao_id: e.target.value
                                        }))
                                    }}
                                >
                                    <option value={null}>
                                        Nenhum
                                    </option>
                                    {dataTable.aplicativos.map((element, index) => {
                                        return (
                                            <option key={"fieldType" + index} value={element.customizacao_id}>
                                                {element.titulo}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Card>
                            <Card.Body className="overflow-auto">
                                <ColumnSortingTable
                                    columns={fields}
                                    data={historyData.history}
                                    getCellProps={(cellinfo) => ({
                                        style: {
                                            textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                            width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                                        }
                                    })}
                                    triggerExport={triggerExport}
                                    setTriggerExport={setTriggerExport}
                                />
                            </Card.Body>
                        </Card>
                        {fieldsResult.length > 0 && filter.customizacao_id > 0 &&
                            <>
                                <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                                    <h5 className="card-title mb-0">Resultados</h5>
                                </Form.Group>
                                <Card>
                                    <Card.Body className="overflow-auto">
                                        <ColumnSortingTable
                                            columns={fieldsResult}
                                            title={dataTable.titulo}
                                            data={historyData.results}
                                            getCellProps={(cellinfo) => ({
                                                style: {
                                                    textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                                    width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                                                }
                                            })}
                                            triggerExport={triggerExportResult}
                                            setTriggerExport={setTriggerExportResult}
                                        />
                                    </Card.Body>
                                </Card>
                            </>}
                    </>
                )}
        </Container>
    );
};

export const EventHistory02 = ({ dataTable, setShowHistory }) => {
    const [historyData, setHistoryData] = useState(false);
    const [updateDataTable, setDataTableUpdate] = useState(false);
    const [updateDataTableWarning, setDataTableUpdateWarning] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [triggerExportUsers, setTriggerExportUsers] = useState(false);
    const userState = useAuth();
    const { setLoading } = useLayout();
    const notyf = useContext(NotyfContext);
    const days = generateDateCategories()
    const [filter, setFilter] = useState({});
    const [fields, setFields] = useState([]);
    const [fieldsUsers, setFieldsUsers] = useState([]);
    const dispatch = useAppDispatch();

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    svg: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    png: {
                        filename: dataTable.titulo + ' - Gráfico',
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow", // full, hollow, inverted
        },
        xaxis: {
            categories: days,
        },
        colors: [
            `#4ba700`
        ],
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    function handleDelete(props) {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <ShowConfirm
                        title="Confirmar ação"
                        description="Deseja remover esse participante? Todos os dados relacionados ao usuário serão excluídos."
                        action={() => {
                            deletParticipant(props, 2);
                            onClose();
                        }}
                        onClose={onClose}
                    />
                );
            }
        });
    }

    function handleExportResult(props) {
        console.log(props)
        setLoading(true)
        axios.get(`/api/event/collectedParticipants/${props.user_id}?event_id=${dataTable.event_id}`)
            .then(function (response) {
                console.log(response)
                let data = response.data
                if (data.length > 0) {
                    let headers = []
                    dataTable.json.form.fields.forEach((element) => {
                        headers.push({ value: element.label, inputID: element.inputID });
                    });
                    getExcelFromObject({ filename: dataTable.titulo + ' - ' + props.email, headers, data });
                } else {
                    notyf.open({
                        type: "danger",
                        message: "Nenhum participante coletado",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error)
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
        // let headers = []
    }

    function deletParticipant(props, platform) {
        setLoading(true)
        axios
            .delete(`/api/history/${props}`, {
                data: { platform: platform }
            })
            .then(function (response) {
                setDataTableUpdate((prevCount) => prevCount + 1);
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    useEffect(() => {
        axios.get(`/api/event/participants/${dataTable.evento_id}?platform=${dataTable.platform}`)
            .then(function (response) {
                setHeaders02(dataTable, setFields, userState, setTriggerExport, handleDelete)
                setHeadersUsers(setFieldsUsers, setTriggerExportUsers, handleDelete, handleExportResult)
                let data = processData02(response.data)
                setHistoryData(data);
                if (updateDataTableWarning === "update") {
                    setDataTableUpdateWarning(false);
                    notyf.open({
                        type: "success",
                        message: "Histórico atualizado com sucesso",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataTable, filter]);

    const data = generateAccessData(historyData.history)
    console.log(historyData.users)
    return (
        <Container fluid className="p-0">
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setDataTableUpdate((prevCount) => prevCount + 1);
                    setDataTableUpdateWarning("update");
                    setLoading(true)
                }}
            >
                <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    if (userState.plataforma_eventos === 1) {
                        setShowHistory(false)
                    } else {
                        dispatch(eventList());
                    }
                }}
            >
                Voltar
            </Button>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        dispatch(eventList());
                    }}
                >
                    Eventos
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Editar evento
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Relatórios
                </Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted my-3">
                Consulte os dados coletados durante o evento
            </h6>
            <Card>
                <Card.Body>
                    <p className="mb-0"><b>Evento: </b>{dataTable.titulo}</p>
                    <p className="mb-0"><b>Status: </b>{statusX[dataTable.status]}</p>
                    <p className="mb-0"><b>Duração: </b>{dataTable.data_criacao} até {dataTable.data_termino}</p>
                </Card.Body>
            </Card>
            {(historyData === false || historyData === undefined || fields.length === 0) ?
                (
                    <div className="py-5">
                        <Loader></Loader>
                    </div>
                ) : (
                    <>

                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">
                                Total de registros ({historyData.participants.length})</h5>
                        </Form.Group>
                        <Card>
                            <Card.Body>
                                <div className="chart">
                                    <Chart options={options} series={data} type="line" height="350" />
                                </div>
                            </Card.Body>
                        </Card>
                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">Lista de usuários</h5>
                        </Form.Group>
                        <h6 className="text-muted my-3 ms-2">
                            Consulte os dados coletados pelos usuários moderadores
                        </h6>
                        <Card>
                            <Card.Body className="overflow-auto">
                                <ColumnSortingTable
                                    columns={fieldsUsers}
                                    title={dataTable.title + ' - usuários'}
                                    data={historyData.users}
                                    getCellProps={(cellinfo) => ({
                                        className: cellinfo.column.id === "icons" ? "table-icons" : "",
                                        style: {
                                            padding: cellinfo.column.id === "imagem" ? "0px 10px" : "",
                                            width: cellinfo.column.id === "imagem" ? "32px" : "",
                                        },
                                    })}
                                    triggerExport={triggerExportUsers}
                                    setTriggerExport={setTriggerExportUsers}
                                />
                            </Card.Body>
                        </Card>
                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">Lista de participantes</h5>
                        </Form.Group>
                        <h6 className="text-muted mb-3 ms-2">
                            Todos os participantes cadastrados no evento
                        </h6>
                        <Card>
                            <Card.Body className="overflow-auto">
                                <ColumnSortingTable
                                    columns={fields}
                                    title={dataTable.title + ' - participantes'}
                                    data={historyData.participants}
                                    getCellProps={(cellinfo) => ({
                                        className: cellinfo.column.id === "icons" ? "table-icons" : "",
                                        style: {
                                            padding: cellinfo.column.id === "imagem" ? "0px 10px" : "",
                                            width: cellinfo.column.id === "imagem" ? "32px" : "",
                                        },
                                    })}
                                    triggerExport={triggerExport}
                                    setTriggerExport={setTriggerExport}
                                />
                            </Card.Body>
                        </Card>
                    </>
                )}
        </Container>
    );
};

function setHeadersResult(setFieldsResult, setTriggerExportResult, handleDeleteResult) {

    const newFields = [];
    newFields.push({
        Header: (
            <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => {
                        setTriggerExportResult(true);
                    }}
                >
                    <FontAwesomeIcon className="cursor-pointer" size={'lg'} icon={faFileExport} />
                </Button>
            </OverlayTrigger>
        ),
        accessor: 'icons',
        disableSortBy: true,
        Filter: false,
        Cell: (cell) => {
            return (
                <>
                    <OverlayTrigger overlay={<Tooltip>Excluir resultado</Tooltip>}>
                        <Trash
                            role="button"
                            className="align-middle cursor-pointer"
                            onClick={() => handleDeleteResult(cell.row.original.resultado_id, cell.row.original.id)}
                            size={18}
                        />
                    </OverlayTrigger>
                </>
            );
        },
    });
    setFieldsResult(newFields);
}

function setHeadersUsers(setFieldsUsers, setTriggerExportResult, handleDelete, handleExportResult) {
    const newFields = [];
    newFields.push({ Header: "Email", accessor: "email" });
    newFields.push({ Header: "Status", accessor: "status_formatted" });
    newFields.push({ Header: "Tipo de usuário", accessor: "type_formatted" });
    newFields.push({ Header: "Código de acesso", accessor: "access_code" });
    newFields.push({ Header: "Data de registro", accessor: "creation_date_formatted" });
    newFields.push({ Header: "Data de registro", accessor: "register_date_formatted" });
    newFields.push({ Header: "Último acesso", accessor: "last_access_formatted" });
    newFields.push({
        Header: (
            <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => {
                        setTriggerExportResult(true);
                    }}
                >
                    <FontAwesomeIcon className="cursor-pointer" size={'lg'} icon={faFileExport} />
                </Button>
            </OverlayTrigger>
        ),
        accessor: 'icons',
        disableSortBy: true,
        Filter: false,
        Cell: (cell) => {
            return (
                <>
                    <OverlayTrigger overlay={<Tooltip>Excluir participante</Tooltip>}>
                        <Trash
                            role="button"
                            className="align-middle me-2 cursor-pointer"
                            onClick={() => handleDelete(cell.row.original.user_id)}
                            size={18}
                        />
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Exportar participantes coletados</Tooltip>}>
                        <Download
                            role="button"
                            className="align-middle cursor-pointer"
                            onClick={() => handleExportResult(cell.row.original)}
                            size={18}
                        />
                    </OverlayTrigger>
                </>
            );
        },
    });
    setFieldsUsers(newFields);
}

function setHeaders02(dataTable, setFields, userState, setTriggerExport, handleDelete) {
    const newFields = [];
    newFields.push({ Header: "Email", accessor: "email" });
    dataTable.json.form.fields.forEach((element) => {
        let maxLength = 40;
        let truncatedString = element.label.length > maxLength
            ? element.label.slice(0, maxLength - 3) + "..."
            : element.label;

        switch (element.type) {
            case 'textarea':
            case 'input':
                if (element.validation === 'number') {
                    newFields.push({
                        Header: truncatedString,
                        accessor: element.label,
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                    });
                } else {
                    newFields.push({
                        Header: truncatedString,
                        accessor: element.label,
                    });
                }
                break;
            case 'select':
                newFields.push({
                    Header: truncatedString,
                    accessor: element.label,
                    Filter: SelectColumnFilter,
                });
                break;
            default:
                newFields.push({
                    Header: truncatedString,
                    accessor: element.label,
                });
                break;
        }
    });
    newFields.push({ Header: "Status", accessor: "status_formatted" });
    newFields.push({ Header: "Código de acesso", accessor: "access_code" });
    newFields.push({ Header: "Data de registro", accessor: "creation_date_formatted" });
    newFields.push({ Header: "Data de registro", accessor: "register_date_formatted" });
    newFields.push({ Header: "Último acesso", accessor: "last_access_formatted" });
    newFields.push({ Header: "ID Externo", accessor: "external_user_id" });
    newFields.push({
        Header: (
            <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => {
                        setTriggerExport(true);
                    }}
                >
                    <FontAwesomeIcon className="cursor-pointer" size={'lg'} icon={faFileExport} />
                </Button>
            </OverlayTrigger>
        ),
        accessor: 'icons',
        disableSortBy: true,
        Filter: false,
        Cell: (cell) => {
            return (
                <>
                    <OverlayTrigger overlay={<Tooltip>Excluir participante</Tooltip>}>
                        <Trash
                            role="button"
                            className="align-middle cursor-pointer"
                            onClick={() => handleDelete(cell.row.original.user_id)}
                            size={18}
                        />
                    </OverlayTrigger>
                </>
            );
        },
    });

    setFields(newFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}