import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Button,
  Breadcrumb,
  Col,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatInTimeZone } from "date-fns-tz";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import NotyfContext from "../../contexts/NotyfContext.js";
import axios from "axios";
import Loader from "../../components/Loader.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import
import { ShowConfirm } from "../../components/Alerts.js";
import useAuth from "../../hooks/useAuth";
import useLayout from "../../hooks/useLayout";
import { jsonCustomizacao, schemaCustomizacao } from "../../components/Json.js";
import {
  GameType,
  ApplicationsList,
  CustomizationsList,
  AppDefinitions
} from "../../components/Applications.js";
import { convertDateToDisplay } from "../../assets/functions.js";

const Aplicativos = () => {
  const [aplicativos, setAplicativos] = useState(false);
  const [dataAplicativo, setDataAplicativo] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/application`)
      .then(function (response) {
        var aplicativos = response.data.message
        setAplicativos(aplicativos);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  if (!aplicativos) {
    return <Loader></Loader>;
  }

  if (!dataAplicativo) {
    return (
      <Container className="p-0">
        <Breadcrumb>
          <Breadcrumb.Item active>Aplicativos</Breadcrumb.Item>
        </Breadcrumb>
        <h6 className="text-muted mb-3">
          Selecione um aplicativo para criar ou editar suas customizações
        </h6>
        <ApplicationsList aplicativos={aplicativos} setDataAplicativo={setDataAplicativo} />
      </Container>
    );
  } else {
    return (
      <Customizations
        dataAplicativo={dataAplicativo}
        setDataAplicativo={setDataAplicativo}
      />
    );
  }
};

export const Customizations = ({ dataAplicativo, setDataAplicativo }) => {
  const [dataTable, setDataTable] = useState(false);
  const { user_id } = useAuth();
  const [fonts, setFonts] = useState()
  const [dataCustomizacao, setDataCustomizacao] = useState("list");
  const [showModalGameType, setShowModalGameType] = useState(false);
  const [updateDataTable, setDataTableUpdate] = useState(false);
  const { setLoading } = useLayout();
  const notyf = useContext(NotyfContext);

  function createCustomization(gameMode = 0) {
    if (dataAplicativo.modes > 0 && gameMode === 0) {
      setShowModalGameType(true);
      return;
    }
    setDataCustomizacao(false);
    let dateX = new Date();
    let dateNow = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm");
    let json = jsonCustomizacao(dataAplicativo.aplicativo_id)
    let data = {
      user_id: user_id,
      aplicativo_id: dataAplicativo.aplicativo_id,
      titulo: '',
      json: JSON.stringify(json),
      data_edicao: dateNow,
      version: 0.0
    }
    axios
      .post("/api/customization", data)
      .then(function (response) {
        data.customizacao_id = response.data.message.insertId
        data.json = JSON.parse(data.json)
        setDataCustomizacao(data);
      })
      .catch(function (error) {
        console.log(error)
        notyf.open({
          type: "error",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function deleteCustomization(customizacao_id) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <ShowConfirm
            title="Confirmar ação"
            description="Deseja remover essa customização?"
            action={handleDelete}
            onClose={onClose}
            param={customizacao_id}
          />
        );
      },
    });
  }

  function handleDelete(customizacao_id) {
    setLoading(true)
    axios
      .delete(`/api/customization/${customizacao_id}`)
      .then(function (response) {
        setLoading(false)
        setDataTableUpdate((prevCount) => prevCount + 1);
      })
      .catch(function (error) {
        setLoading(false)
        notyf.open({
          type: "danger",
          message: error.response.data.message,
          ripple: true,
          dismissible: true,
        });
      });
  }

  useEffect(() => {
    if (!fonts) {
      //Get google fonts
      axios
        .get(
          `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBQSHG64iczeP5lb7OvezR9kkn0LSU8vEc&sort=popularity`
        )
        .then((response) => {
          let placeIDs = [];
          response.data.items.slice([0], [100]).map((item, i) => {
            return placeIDs.push({ font: item.family });
          });
          setFonts(placeIDs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //Get aplicativo customizations
    axios
      .get(`/api/customization?user_id=${user_id}&aplicativo_id=${dataAplicativo.aplicativo_id}`)
      .then(function (response) {
        var data = response.data.message
        data.forEach(function callback(value, index) {
          try {
            value.json = JSON.parse(value.json);
          } catch (error) {
            value.json = {}
          }
          value.data_edicao = convertDateToDisplay(
            value.data_edicao
          );
        });
        setLoading(false)
        setDataTable(data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataTable, dataAplicativo]);

  if (!dataTable || !dataCustomizacao) {
    return <Loader></Loader>;
  }

  if (dataCustomizacao === "list") {
    return (
      <>
        <Container className="p-0">
          <Button
            variant="primary"
            className="float-end"
            onClick={() => {
              createCustomization();
            }}
          >
            <FontAwesomeIcon icon={faPlus} size="1x" />
          </Button>
          <Button
            variant="primary"
            className="float-end me-2"
            onClick={() => {
              setDataAplicativo(false);
            }}
          >
            Voltar
          </Button>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                setDataAplicativo(false);
              }}>
              Aplicativos
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{dataAplicativo.nome}</Breadcrumb.Item>
          </Breadcrumb>
          <h6 className="text-muted mb-4">{dataAplicativo.descricao}</h6>
          <CustomizationsList customizations={dataTable} setDataCustomizacao={setDataCustomizacao} deleteCustomization={deleteCustomization}></CustomizationsList>
        </Container>
        <GameType
          dataAplicativo={dataAplicativo}
          createCustomization={createCustomization}
          showModalGameType={showModalGameType}
          setShowModalGameType={setShowModalGameType}
        />
      </>
    );
  } else {
    return (
      <Edit
        fonts={fonts}
        dataAplicativo={dataAplicativo}
        dataCustomizacao={dataCustomizacao}
        setDataCustomizacao={setDataCustomizacao}
        setDataTableUpdate={setDataTableUpdate}
        setDataAplicativo={setDataAplicativo}
        notyf={notyf}
      />
    );
  }
};

const Edit = ({
  fonts,
  dataAplicativo,
  dataCustomizacao,
  setDataCustomizacao,
  setDataTableUpdate,
  setDataAplicativo,
  notyf,
}) => {
  const { setLoading } = useLayout();
  const { user_id } = useAuth();

  let schema;
  schema = schemaCustomizacao(dataAplicativo.aplicativo_id);

  function handlePreviewSuccess(filename, setFieldValue, field) {
    setFieldValue(field, filename);
  }

  function handlePreviewError(error) {
    notyf.open({
      type: "danger",
      message: error,
      ripple: true,
      dismissible: true,
    });
  }

  function updateCustomization(values, actions) {
    let dateX = new Date();
    let dateNow = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm");
    setLoading(true)
    axios
      .put(`/api/customization/${values.customizacao_id}`, {
        titulo: values.titulo,
        json: values.json,
        data_edicao: dateNow,
        version: parseFloat(values.version) + 0.1,
        user_id: user_id
      })
      .then(function (response) {
        if (response.status === 200) {
          setLoading(false)
          const handleOnClose = (onClose = undefined) => {
            setDataCustomizacao("list");
            setLoading(true)
            setDataTableUpdate((prevCount) => prevCount + 1);
            if (onClose) {
              onClose();
            }
            notyf.open({
              type: "success",
              message: "Customização salva com sucesso",
              ripple: true,
              dismissible: true,
            });
          };

          if (response.data.elementsToUpdate) {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,
              customUI: ({ onClose }) => {
                return (
                  <ShowConfirm
                    title="Customização em uso"
                    description="Deseja atualizar todas as customizações que estão em uso? Você também pode atualizar manualmente ao editar o evento"
                    action={updateCustomizationVersion}
                    onClose={(e) => handleOnClose(onClose)}
                    param={{ customizacao_id: values.customizacao_id }}
                    textConfirm={'Sim, atualizar customizações'}
                    textCancel={'Não'}
                  />
                );
              },
            });
          } else {
            handleOnClose(undefined)
          }
        } else {
          setLoading(false)
          notyf.open({
            type: "error",
            message: "Houve um erro, tente novamente",
            ripple: true,
            dismissible: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        notyf.open({
          type: "error",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function updateCustomizationVersion({ customizacao_id }) {
    setLoading(true)
    axios.put(`/api/event/customizationVersion/${customizacao_id}`, {
      user_id: user_id
    })
      .then(function (response) {
        setLoading(false)
        notyf.open({
          type: "success",
          message: "Eventos com essa customização foram atualizados",
          ripple: true,
          dismissible: true,
        });
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  return (
    <React.Fragment>
      <Container className="p-0">
        <Formik
          validationSchema={schema}
          onSubmit={updateCustomization}
          enableReinitialize
          initialValues={{
            customizacao_id: dataCustomizacao.customizacao_id,
            user_id: dataCustomizacao.user_id,
            aplicativo_id: dataCustomizacao.aplicativo_id,
            titulo: dataCustomizacao.titulo,
            json: dataCustomizacao.json,
            data_edicao: dataCustomizacao.data_edicao,
            version: dataCustomizacao.version
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isValid,
            errors,
            dirty,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="mb-4">
                <Button
                  variant="primary"
                  className="float-end me-2"
                  onClick={() => {
                    setDataCustomizacao("list");
                    setLoading(true)
                    setDataTableUpdate((prevCount) => prevCount + 1);
                  }}
                >
                  Voltar
                </Button>
                <Breadcrumb>
                  <Breadcrumb.Item
                    onClick={() => {
                      setDataAplicativo(false);
                    }}
                  >
                    Aplicativos
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => {
                      setDataCustomizacao("list");
                      setDataTableUpdate((prevCount) => prevCount + 1);
                      setLoading(true)
                    }}
                  >
                    {dataAplicativo.nome}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {values.titulo ? "Editar customização" : "Nova customização"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <AppDefinitions fonts={fonts} aplicativo_id={dataAplicativo.aplicativo_id} handleChange={handleChange} values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} handlePreviewSuccess={handlePreviewSuccess} handlePreviewError={handlePreviewError}></AppDefinitions>
              <hr></hr>
              <Form.Group as={Col}>
                <Button
                  type="submit"
                  className="float-end"
                  disabled={isSubmitting}
                >
                  Salvar
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default Aplicativos;
