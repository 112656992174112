import React, { useState } from "react";
import {
    Button,
    Col,
    Card,
    Badge,
    Row,
    Form,
    Tooltip,
    OverlayTrigger,
    InputGroup,
    ButtonToolbar
} from "react-bootstrap";
import {
    faExclamationCircle,
    faClock,
    faClose
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopoverPicker from "../../components/PopoverPicker.js";
import DropzoneComponent from "../Dropzone.js";
import { domainConfig } from "../../config.js";
import YouTubeEmbed from "../YoutubeEmbed.js";
import {
    HelpTooltip,
    handleYoutubeUrl
} from "../../assets/functions.js";

const Quiz = ({
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [updateCustomizacao, setUpdateCustomizacao] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.backgroundColor,
        textColor: values.json.apresentation?.textSide?.textColor,
    });

    const exerciseType = (exercise) => {
        switch (exercise) {
            case 1:
                return "Múltipla escolha";
            case 2:
                return "Enquete";
            case 3:
                return "Arrastar e soltar";
            default:
                return "";
        }
    };

    function newQuestion() {
        let newID = values.json.questions.length;
        values.json.questions.push({
            question_id: newID + 1,
            exercise: 1,
            type: 1,
            col: 6,
            question: "",
            url: "",
            value: 50,
            shuffleAnswers: true,
            answers: [
                {
                    text: "",
                    isCorrect: false,
                    order: 0,
                },
                {
                    text: "",
                    isCorrect: false,
                    order: 1,
                },
                {
                    text: "",
                    isCorrect: false,
                    order: 2,
                },
                {
                    text: "",
                    isCorrect: false,
                    order: 3,
                },
            ],
        });
        setCurrentQuestion(newID);
    }

    function deleteQuestion(index) {
        values.json.questions.splice(index, 1);
        values.json.questions = values.json.questions.filter(function (e) {
            return e != null;
        });
        const newIndex = index - 1;
        if (values.json.questions.length === 0) {
            return;
        }
        if (newIndex >= 0) {
            setCurrentQuestion(newIndex);
        } else {
            setCurrentQuestion(0);
            setUpdateCustomizacao((prevState) => prevState + 1);
        }
    }

    console.log(errors)
    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        className="w-auto"
                        checked={values.json.sound ? true : false}
                        type="checkbox"
                        name="json.sound"
                        label="Ativar sons interativos"
                        onChange={(e) => {
                            setFieldValue(
                                "json.sound",
                                values.json.sound ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.showRanking ? true : false}
                        type="checkbox"
                        name="json.showRanking"
                        label='Mostrar ranking após cada exercício'
                        onChange={(e) => {
                            setFieldValue(
                                "json.showRanking",
                                values.json.showRanking ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.showChart === true ? true : false}
                        type="checkbox"
                        name="json.showChart"
                        label="Mostrar gráficos após cada exercício"
                        onChange={(e) => {
                            setFieldValue(
                                "json.showChart",
                                values.json.showChart === true ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.reset?.status === true ? true : false}
                        type="checkbox"
                        name="json.reset.status"
                        label="Adicionar botão de voltar para página de registro"
                        onChange={(e) => {
                            setFieldValue(
                                "json.reset.status",
                                values.json.reset?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.reset?.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="validationFormik06"
                            >
                                <Form.Control
                                    name="json.reset.text"
                                    value={values.json.reset?.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.reset?.text}
                                    isValid={
                                        touched.json?.reset?.text &&
                                        !errors.json?.reset?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.reset?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                    <Form.Check
                        checked={values.json.timer?.status === true ? true : false}
                        type="checkbox"
                        name="json.timer.status"
                        label="Limite de tempo"
                        onChange={(e) => {
                            setFieldValue(
                                "json.timer.status",
                                values.json.timer?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.timer?.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="validationFormik06"
                            >
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faClock} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        name="json.timer.value"
                                        value={values.json.timer.value}
                                        onChange={handleChange}
                                        isInvalid={!!errors.json?.timer?.value}
                                        isValid={
                                            touched.json?.timer?.value &&
                                            !errors.json?.timer?.value
                                        }
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.timer?.value}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-2">
                <h5 className="card-title mb-0">Exercícios</h5>
            </Form.Group>
            <Row className="mx-0 mt-3 mb-0">
                <Form.Group
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        checked={values.json.shuffleQuestions === true ? true : false}
                        type="checkbox"
                        name="json.shuffleQuestions"
                        label={
                            <>
                                Embaralhar ordenação dos exercícios
                                <OverlayTrigger
                                    overlay={<Tooltip>Adicione o número das questões que você deseja manter fixo na ordenação, separado por vírgulas. Ex: 1,3,5</Tooltip>}
                                >
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger>
                            </>
                        }
                        onChange={(e) => {
                            setFieldValue(
                                "json.shuffleQuestions",
                                values.json.shuffleQuestions === true ? false : true
                            );
                        }}
                    />
                    {values.json.shuffleQuestions === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="validationFormik06"
                            >
                                <Form.Control
                                    type="text"
                                    name="json.shuffleQuestionsArray"
                                    value={values.json.shuffleQuestionsArray}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.shuffleQuestionsArray}
                                    isValid={
                                        touched.json?.shuffleQuestionsArray &&
                                        !errors.json?.shuffleQuestionsArray
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.shuffleQuestionsArray}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                    <Form.Check
                        checked={values.json.answerFeedback === true ? true : false}
                        type="checkbox"
                        name="json.answerFeedback"
                        label="Feedback de acerto ou erro"
                        onChange={(e) => {
                            setFieldValue(
                                "json.answerFeedback",
                                values.json.answerFeedback === true ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.correctFeedback === true ? true : false}
                        type="checkbox"
                        name="json.correctFeedback"
                        label="Destacar resposta correta"
                        onChange={(e) => {
                            setFieldValue(
                                "json.correctFeedback",
                                values.json.correctFeedback === true ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.selectQuestions?.status === true ? true : false}
                        type="checkbox"
                        name="json.selectQuestions.status"
                        label={
                            <>
                                Limite de exercícios
                                <OverlayTrigger
                                    overlay={<Tooltip>Ao definir um valor menor que o total de exercícios, é feita uma seleção aleatória de exercícios antes de iniciar o Quiz</Tooltip>}
                                >
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger>
                            </>
                        }
                        onChange={(e) => {
                            setFieldValue(
                                "json.selectQuestions.status",
                                values.json.selectQuestions?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.selectQuestions?.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="validationFormik06"
                            >
                                <Form.Control
                                    type="number"
                                    name="json.selectQuestions.value"
                                    value={values.json.selectQuestions?.value}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.selectQuestions?.value}
                                    isValid={
                                        touched.json?.selectQuestions?.value &&
                                        !errors.json?.selectQuestions?.value
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.selectQuestions?.value}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <hr></hr>
            <ButtonToolbar className="mb-3 justify-content-center">
                {values.json.questions?.map((element, index) => {
                    let hasError = false
                    if (errors?.json?.questions?.[index]) {
                        hasError = true
                    }
                    return (
                        <Button
                            key={"buttonQuestions" + index}
                            className={`mt-3 me-2 buttonToolbarApp ${hasError ? 'is-invalid' : ''} ${currentQuestion === index ? 'active' : ''}`}
                            onClick={() => setCurrentQuestion(index)}
                        >
                            {index + 1}
                        </Button>
                    );
                })}
                <Button onClick={() => newQuestion()} className="mt-3 ms-2">
                    Adicionar
                </Button>
            </ButtonToolbar>
            {values.json.questions?.map((element, index) => {
                if (index !== currentQuestion) {
                    return ("")
                }
                return (
                    <Row className="mx-0 mb-3" key={'questionsCard' + index}>
                        <Form.Group
                            as={Col}
                            sm="6"
                            controlId="validationFormik001"
                            className="mb-3"
                        >
                            <Form.Label>Tipo de exercício</Form.Label>
                            <Form.Select
                                name={`json.questions[${index}].exercise`}
                                value={values.json.questions[index]?.exercise}
                                onChange={(e) => {
                                    setFieldValue(
                                        `json.questions[${index}].exercise`,
                                        parseInt(e.target.value)
                                    );
                                    setUpdateCustomizacao((prevState) => prevState + 1);
                                }}
                            >
                                <option value={1}>{exerciseType(1)}</option>
                                <option value={2}>{exerciseType(2)}</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            className={"mb-3"}
                            sm={"6"}
                            controlId="validationFormik03">
                            <Form.Label>Tamanho do campo <OverlayTrigger
                                overlay={<Tooltip>Valor de 12 ocupa 100% do espaço</Tooltip>}
                            >
                                <HelpTooltip icon={faExclamationCircle} />
                            </OverlayTrigger>
                            </Form.Label>
                            <Form.Select
                                name="col"
                                value={values.json.questions[index].col}
                                onChange={(e) => {
                                    setFieldValue(`json.questions[${index}].col`, parseInt(e.target.value));
                                }}
                                isInvalid={!!errors.json?.questions?.[index]?.col}
                                isValid={touched.json?.questions?.[index]?.col && !errors.json?.questions?.[index]?.col}>
                                {[4, 6, 8, 12].map((element, index) => {
                                    return (
                                        <option key={"colField" + index} value={element}>
                                            {element}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.questions?.[index]?.col}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik08"
                        >
                            <Form.Label className="d-flex align-items-center">
                                Questão
                                <Badge
                                    pill
                                    className={`${values.json.questions[index]?.type === 1
                                        ? "ms-2 bg-primary cursor-pointer"
                                        : "ms-2 border border-primary bg-white text-primary cursor-pointer"
                                        }`}
                                    onClick={(e) => {
                                        setFieldValue(`json.questions[${index}].type`, 1);
                                    }}
                                >
                                    Texto
                                </Badge>
                                <Badge
                                    pill
                                    className={`${values.json.questions[index]?.type === 2
                                        ? "ms-2 bg-primary cursor-pointer"
                                        : "ms-2 border border-primary bg-white text-primary cursor-pointer"
                                        }`}
                                    onClick={(e) => {
                                        setFieldValue(`json.questions[${index}].type`, 2);
                                    }}
                                >
                                    Imagem
                                </Badge>
                                <Badge
                                    pill
                                    className={`${values.json.questions[index]?.type === 3
                                        ? "ms-2 bg-primary cursor-pointer"
                                        : "ms-2 border border-primary bg-white text-primary cursor-pointer"
                                        }`}
                                    onClick={(e) => {
                                        setFieldValue(`json.questions[${index}].type`, 3);
                                    }}
                                >
                                    Video
                                </Badge>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                name={`json.questions[${index}].question`}
                                value={values.json.questions[index].question}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.questions?.[index]?.question}
                                isValid={touched.json?.questions?.[index]?.question && !errors.json?.questions?.[index]?.question
                                }
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.questions?.[index]?.question}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <QuestionType
                            values={values}
                            currentQuestion={index}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handlePreviewSuccess={handlePreviewSuccess}
                            handlePreviewError={handlePreviewError}
                        ></QuestionType>
                        <ExerciseType
                            setUpdateCustomizacao={setUpdateCustomizacao}
                            values={values}
                            currentQuestion={index}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        ></ExerciseType>
                        {values.json.questions?.length > 1 && <Col className="text-center">
                            <Button
                                variant="outline-danger"
                                className="mb-3"
                                onClick={() => deleteQuestion(currentQuestion)}
                            >
                                Excluir
                            </Button>
                        </Col>}
                    </Row >
                )
            })}
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Participante</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela de resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.endText.title.value"
                        value={values.json.endText.title.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.endText.title.status", false);
                            } else {
                                setFieldValue("json.endText.title.status", true);
                            }
                            setFieldValue("json.endText.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.endText?.title?.value}
                        isValid={touched?.json?.endText?.title?.value && !errors.json?.endText?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.endText?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.endText.description.description"
                        value={values.json.endText.description.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.endText.description.status", false);
                            } else {
                                setFieldValue("json.endText.description.status", true);
                            }
                            setFieldValue("json.endText.description.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.endText?.description?.value}
                        isValid={touched?.json?.endText?.description?.value && !errors.json?.endText?.description?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.endText?.description?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        checked={values.json.showScore ? true : false}
                        type="checkbox"
                        name="json.showScore"
                        label='Mostrar pontuação ao participante'
                        onChange={(e) => {
                            setFieldValue(
                                "json.showScore",
                                values.json.showScore ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.retry.status === true ? true : false}
                        type="checkbox"
                        name="json.retry.status"
                        label="Opção de jogar novamente"
                        onChange={(e) => {
                            setFieldValue(
                                "json.retry.status",
                                values.json.retry.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.retry?.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="validationFormik06"
                            >
                                <Form.Control
                                    name="json.retry.text"
                                    value={values.json.retry.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.retry?.text}
                                    isValid={
                                        touched.json?.retry?.text &&
                                        !errors.json?.retry?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.retry?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Modo apresentação</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.apresentation.rankingPage.title.value"
                        value={values.json.apresentation?.rankingPage?.title?.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.apresentation.rankingPage.title.status", false);
                            } else {
                                setFieldValue("json.apresentation.rankingPage.title.status", true);
                            }
                            setFieldValue("json.apresentation.rankingPage.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.apresentation?.rankingPage?.title?.value}
                        isValid={touched?.json?.apresentation?.rankingPage?.title?.value && !errors?.json?.apresentation?.rankingPage?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.rankingPage?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-4">
                    <Form.Label>Cor de fundo e texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor || errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.backgroundColor"
                            value={values.json?.apresentation?.textSide?.backgroundColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.backgroundColor}
                            isValid={touched?.json?.apresentation?.textSide?.backgroundColor && !errors?.json?.apresentation?.textSide?.backgroundColor}
                        />
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.backgroundColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.backgroundColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    backgroundColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : errors?.json?.apresentation?.textSide?.backgroundColor ? errors?.json?.apresentation?.textSide?.backgroundColor : ''}
                    </Form.Control.Feedback>
                </div>
                <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationFormik03"
                >
                    <Form.Label>Tipo de layout</Form.Label>
                    <Form.Select
                        onChange={(e) => {
                            // eslint-disable-next-line eqeqeq
                            if (e.target.value == 2) {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 40
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 60
                                );
                            } else {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 0
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 100
                                );
                            }
                        }}
                        value={values.json.apresentation?.imageSide?.value && values.json.apresentation?.imageSide?.value > 0 ? 2 : 1}>
                        <option value={1}>
                            Simples
                        </option>
                        <option value={2}>
                            Com banner
                        </option>
                    </Form.Select>
                </Form.Group>
            </Row>
            {values.json.apresentation?.imageSide?.value > 0 &&
                <>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                            controlId="validationFormik01">
                            <Form.Label>Tamanho do banner (%)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.apresentation.imageSide.value"
                                value={values.json.apresentation?.imageSide?.value}
                                onChange={(e) => {
                                    if (e.target.value === undefined || e.target.value === '') {
                                        e.target.value = 0
                                    }
                                    let textSideValue = 100 - e.target.value
                                    setFieldValue("json.apresentation.imageSide.value", e.target.value);
                                    setFieldValue("json.apresentation.textSide.value", textSideValue);
                                }}
                                isInvalid={!!errors.json?.apresentation?.imageSide?.value}
                                isValid={touched.json?.apresentation?.imageSide?.value && !errors.json?.apresentation?.imageSide?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.apresentation?.imageSide?.value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                            controlId="validationFormik03"
                        >
                            <Form.Label>Posição</Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                name="json.apresentation.imageSide.position"
                                value={values.json.apresentation?.imageSide?.position}>
                                <option value={'start'}>
                                    Esquerda
                                </option>
                                <option value={'end'}>
                                    Direita
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Banner</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.imageSide.background"
                                        value={values.json.apresentation?.imageSide?.background}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.imageSide?.background}
                                        isValid={touched?.json?.apresentation?.imageSide?.background && !errors?.json?.apresentation?.imageSide?.background}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.imageSide?.background ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation?.imageSide?.background !== "" && values.json.apresentation?.imageSide?.background !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation?.imageSide?.background
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.imageSide?.background}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.imageSide.background"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                </>}
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        checked={values.json.apresentation?.showQRCode?.status === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.showQRCode.status"
                        label="Mostrar QRCode"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showQRCode.status",
                                values.json.apresentation?.showQRCode?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.apresentation?.showQRCode?.status === true ? (
                        <Row>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06">
                                <Form.Label>Texto</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.text"
                                    value={values.json.apresentation.showQRCode.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.text}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.text &&
                                        !errors.json?.apresentation?.showQRCode?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06"
                            >
                                <Form.Label>URL<OverlayTrigger overlay={<Tooltip>Caso nenhuma URL seja fornecida o QRCode irá redirecionar o usuário ao evento</Tooltip>}>
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.link"
                                    value={values.json.apresentation.showQRCode.link}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.link}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.link &&
                                        !errors.json?.apresentation?.showQRCode?.link
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06"
                            >
                                <Form.Label>Posição Y</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.y", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.y}>
                                    <option value={'top'}>
                                        Topo
                                    </option>
                                    <option value={'bottom'}>
                                        Fundo
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.y}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06"
                            >
                                <Form.Label>Posição X</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.x", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.x}>
                                    <option value={'left'}>
                                        Esquerda
                                    </option>
                                    <option value={'right'}>
                                        Direita
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.x}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
        </>
    );
};

const QuestionType = ({
    values,
    currentQuestion,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handlePreviewSuccess,
    handlePreviewError

}) => {

    switch (values.json.questions[currentQuestion]?.type) {
        case 2:
            return (
                <Form.Group
                    className={`mb-3`}
                    as={Col}
                    md="12">
                    <Card className="mb-0">
                        <Card.Body>
                            <Form.Control
                                type="hidden"
                                name={`json.questions[${currentQuestion}].url`}
                                value={values.json.questions[currentQuestion].url}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.questions?.[currentQuestion]?.url}
                                isValid={touched.json?.questions?.[currentQuestion]?.url && !errors.json?.questions?.[currentQuestion]?.url}
                            />
                            <div className="imagesUpload mt-3 ">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors.json?.questions?.[currentQuestion]?.url ? "is-invalid" : ""}`}
                                    src={
                                        values.json.questions[currentQuestion].url !== "" &&
                                            values.json.questions[currentQuestion].url !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.questions[currentQuestion].url
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem aplicativo"
                                />

                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.questions?.[currentQuestion]?.url}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className={`mt-3 dropzoneComp`}
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field={`json.questions[${currentQuestion}].url`}
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            );
        case 3:
            return (
                <Form.Group
                    className={`mb-3`}
                    as={Col}
                    md="12">
                    <Card className="mb-0">
                        <Card.Body>
                            <div className={"h-100 img-thumbnail mt-3 imagesUpload"}>
                                <YouTubeEmbed
                                    videoId={handleYoutubeUrl(
                                        values.json.questions[currentQuestion].url
                                    )}
                                />
                                <div className="px-3">
                                    <Form.Label className="d-flex align-items-center">
                                        Youtube URL
                                    </Form.Label>
                                    <Form.Control
                                        className="mt-2 px-3"
                                        name={`json.questions[${currentQuestion}].url`}
                                        value={values.json.questions[currentQuestion].url}
                                        onChange={handleChange}
                                        isInvalid={!!errors.json?.questions?.[currentQuestion]?.url}
                                        isValid={touched.json?.questions?.[currentQuestion]?.url && !errors.json?.questions?.[currentQuestion]?.url}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.questions?.[currentQuestion]?.url}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Form.Group>
            );
        default:
            return "";
    }
};

const ExerciseType = ({
    setUpdateCustomizacao,
    values,
    currentQuestion,
    errors,
    touched,
    setFieldValue,
    handleChange,
}) => {
    function removeAnswer(order) {
        const index = values.json.questions[currentQuestion].answers.findIndex(
            (obj) => obj.order === order
        );
        values.json.questions[currentQuestion].answers.splice(index, 1);
        values.json.questions[currentQuestion].answers = values.json.questions[
            currentQuestion
        ].answers.filter(function (e) {
            return e != null;
        });
        const hasCorrectAnswer = values.json.questions[currentQuestion].answers.some(
            (answer) => answer.isCorrect === true
        );
        if (!hasCorrectAnswer) {
            values.json.questions[currentQuestion].answers[0].isCorrect = true
        }
        setUpdateCustomizacao((prevState) => prevState + 1);
    }

    function addAnswer() {
        values.json.questions[currentQuestion].answers.push({
            text: "",
            isCorrect: false,
            order: values.json.questions[currentQuestion].answers.length,
        });
        setUpdateCustomizacao((prevState) => prevState + 1);
    }

    switch (values.json.questions[currentQuestion].exercise) {
        case 1:
            return (
                <>
                    <Form.Group
                        className="mb-2"
                        as={Col}
                        sm="6"
                        controlId="validationFormik09"
                    >
                        <Form.Label className="d-flex align-items-center">
                            Pontuação
                            <Badge variant="bg-primary" className="mx-2">
                                {values.json.questions[currentQuestion].value}
                            </Badge>
                        </Form.Label>
                        <input
                            type="range"
                            className="form-range"
                            id="customRange1"
                            value={values.json.questions[currentQuestion].value}
                            min="0"
                            max="100"
                            step="10"
                            onChange={(e) => {
                                setFieldValue(
                                    `json.questions[${currentQuestion}].value`,
                                    e.target.value
                                );
                            }}
                        />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        sm="12"
                        className="mb-3"
                        controlId="validationFormik11"
                    >
                        <Form.Check
                            checked={
                                values.json.questions[currentQuestion].shuffleAnswers === true
                                    ? true
                                    : false
                            }
                            type="checkbox"
                            name={`values.json.questions[${currentQuestion}].shuffleAnswers`}
                            label="Embaralhar ordenação das respostas"
                            onChange={(e) => {
                                setFieldValue(
                                    `json.questions[${currentQuestion}].shuffleAnswers`,
                                    values.json.questions[currentQuestion].shuffleAnswers === true
                                        ? false
                                        : true
                                );
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm="12" controlId="validationFormik09">
                        <Form.Label>
                            Respostas
                            {values.json.questions[currentQuestion].answers.length < 10 && (
                                <Badge
                                    pill
                                    className={"ms-2 bg-primary cursor-pointer"}
                                    onClick={(e) => addAnswer()}
                                >
                                    Adicionar
                                </Badge>
                            )}
                        </Form.Label>
                        {values.json.questions[currentQuestion].answers.map(
                            (element, index) => {
                                return (
                                    <>
                                        <Form.Group
                                            key={"questions" + index}
                                            className="mb-3"
                                            as={Col}
                                            sm="12"
                                            controlId="validationFormik10"
                                        >
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    name={`json.questions[${currentQuestion}].answers[${index}].text`}
                                                    value={values.json.questions[currentQuestion].answers[index].text}
                                                    onChange={handleChange}
                                                    isInvalid={touched.json?.questions?.[currentQuestion]?.answers?.[index]?.text && !!errors.json?.questions?.[currentQuestion]?.answers?.[index]?.text}
                                                    isValid={touched.json?.questions?.[currentQuestion]?.answers?.[index]?.text && !errors.json?.questions?.[currentQuestion]?.answers?.[index]?.text}
                                                />
                                                <InputGroup.Checkbox
                                                    className="pointer"
                                                    checked={
                                                        values.json.questions[currentQuestion].answers[
                                                            index
                                                        ].isCorrect === true
                                                            ? true
                                                            : false
                                                    }
                                                    name={`values.json.questions[${currentQuestion}].answers[${index}].isCorrect`}
                                                    onChange={(e) => {
                                                        values.json.questions[currentQuestion].answers.forEach((_, key) => {
                                                            setFieldValue(
                                                                `json.questions[${currentQuestion}].answers[${key}].isCorrect`,
                                                                key === index ? true : false
                                                            );
                                                        });
                                                    }}
                                                />
                                                {values.json.questions[currentQuestion].answers.length >
                                                    1 && (
                                                        <Button
                                                            type="button"
                                                            variant="outline-danger"
                                                            onClick={() => removeAnswer(element.order)}
                                                        >
                                                            <FontAwesomeIcon icon={faClose} />
                                                        </Button>
                                                    )}
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.json?.questions?.[currentQuestion]?.answers?.[index]?.text}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </>
                                );
                            }
                        )}
                    </Form.Group>
                </>
            );
        case 2:
            return (
                <>
                    <Form.Group
                        as={Col}
                        sm="12"
                        className="mb-3"
                        controlId="validationFormik11"
                    >
                        <Form.Check
                            checked={
                                values.json.questions[currentQuestion].shuffleAnswers === true
                                    ? true
                                    : false
                            }
                            type="checkbox"
                            name={`values.json.questions[${currentQuestion}].shuffleAnswers`}
                            label="Embaralhar ordenação das respostas"
                            onChange={(e) => {
                                setFieldValue(
                                    `json.questions[${currentQuestion}].shuffleAnswers`,
                                    values.json.questions[currentQuestion].shuffleAnswers === true
                                        ? false
                                        : true
                                );
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm="12" controlId="validationFormik09">
                        <Form.Label>
                            Respostas
                            {values.json.questions[currentQuestion].answers.length < 10 && (
                                <Badge
                                    pill
                                    className={"ms-2 bg-primary cursor-pointer"}
                                    onClick={(e) => addAnswer()}
                                >
                                    Adicionar
                                </Badge>
                            )}
                        </Form.Label>
                        {values.json.questions[currentQuestion].answers.map(
                            (element, index) => {
                                return (
                                    <>
                                        <Form.Group
                                            key={"questions" + index}
                                            className="mb-3"
                                            as={Col}
                                            sm="12"
                                            controlId="validationFormik10"
                                        >
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    name={`json.questions[${currentQuestion}].answers[${index}].text`}
                                                    value={values.json.questions[currentQuestion].answers[index].text}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.json?.questions?.[currentQuestion]?.answers?.[index]?.text
                                                    }
                                                    isValid={touched.json?.questions?.[currentQuestion]?.answers?.[index]?.text && !errors.json?.questions?.[currentQuestion]?.answers?.[index]?.text}
                                                />
                                                {values.json.questions[currentQuestion].answers.length >
                                                    1 && (
                                                        <Button
                                                            type="button"
                                                            variant="outline-danger"
                                                            onClick={() => removeAnswer(element.order)}
                                                        >
                                                            <FontAwesomeIcon icon={faClose} />
                                                        </Button>
                                                    )}
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.json?.questions?.[currentQuestion]?.answers?.[index]?.text}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </>
                                );
                            }
                        )}
                    </Form.Group>
                </>
            );

        default:
            return "";
    }
};

export default Quiz