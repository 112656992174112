import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import Logo from "../../assets/img/logoFlexGreenThumb.png";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Sign In" />
    <Card>
      <Card.Body>
        <div
          className="text-center"
          id="loginLogo"
        >
          <img
            src={Logo}
            alt="Flex Interativa"
            className="img-fluid"
            width="250"
            height="250"
          />
        </div>
        <h4 className="text-center mt-3 mb-0">
          Painel Administrativo
        </h4>
        <SignIn />
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignInPage;
