import * as React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <React.Fragment></React.Fragment>;
  }
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
