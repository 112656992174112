import React from "react";
import { Button, Alert } from "react-bootstrap";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-regular-svg-icons";

// const colors = ["primary", "secondary", "success", "danger", "warning", "info"];

// const DefaultAlerts = () => (
//   <Card>
//     <Card.Header>
//       <Card.Title>Default alerts</Card.Title>
//       <h6 className="card-subtitle text-muted">
//         Alerts with contextual background color.
//       </h6>
//     </Card.Header>
//     <Card.Body>
//       {colors.map((color, key) => (
//         <Alert variant={color} key={key} dismissible>
//           <div className="alert-message">
//             <strong>Hello there!</strong> A simple {color} alert—check it out!
//           </div>
//         </Alert>
//       ))}
//     </Card.Body>
//   </Card>
// );

// const IconAlerts = () => (
//   <Card>
//     <Card.Header>
//       <Card.Title>Icon alerts</Card.Title>
//       <h6 className="card-subtitle text-muted">
//         Alerts with icon and background color.
//       </h6>
//     </Card.Header>
//     <Card.Body>
//       {colors.map((color, key) => (
//         <Alert variant={color} key={key} dismissible>
//           <div className="alert-icon">
//             <FontAwesomeIcon icon={faBell} fixedWidth />
//           </div>
//           <div className="alert-message">
//             <strong>Hello there!</strong> A simple {color} alert—check it out!
//           </div>
//         </Alert>
//       ))}
//     </Card.Body>
//   </Card>
// );

// const OutlineAlerts = () => (
//   <Card>
//     <Card.Header>
//       <Card.Title>Outline alerts</Card.Title>
//       <h6 className="card-subtitle text-muted">
//         Alerts with contextual icon background.
//       </h6>
//     </Card.Header>
//     <Card.Body>
//       {colors.map((color, key) => (
//         <Alert variant={color} className="alert-outline" key={key} dismissible>
//           <div className="alert-icon">
//             <FontAwesomeIcon icon={faBell} fixedWidth />
//           </div>
//           <div className="alert-message">
//             <strong>Hello there!</strong> A simple {color} alert—check it out!
//           </div>
//         </Alert>
//       ))}
//     </Card.Body>
//   </Card>
// );

// const ColouredOutlineAlerts = () => (
//   <Card>
//     <Card.Header>
//       <Card.Title>Colored outline alerts</Card.Title>
//       <h6 className="card-subtitle text-muted">
//         Alerts with contextual outline color.
//       </h6>
//     </Card.Header>
//     <Card.Body>
//       {colors.map((color, key) => (
//         <Alert
//           variant={color}
//           className="alert-outline-coloured"
//           key={key}
//           dismissible
//         >
//           <div className="alert-icon">
//             <FontAwesomeIcon icon={faBell} fixedWidth />
//           </div>
//           <div className="alert-message">
//             <strong>Hello there!</strong> A simple {color} alert—check it out!
//           </div>
//         </Alert>
//       ))}
//     </Card.Body>
//   </Card>
// );

// const AdditionalContentAlerts = () => (
//   <Card>
//     <Card.Header>
//       <Card.Title>Alerts with additonal content</Card.Title>
//       <h6 className="card-subtitle text-muted">Alerts with large contents.</h6>
//     </Card.Header>
//     <Card.Body>
//       <Alert variant="primary" dismissible>
//         <div className="alert-message">
//           <h4 className="alert-heading">Well done!</h4>
//           <p>
//             Aww yeah, you successfully read this important alert message. This
//             example text is going to run a bit longer so that you can see how
//             spacing within an alert works with this kind of content.
//           </p>
//           <hr />
//           <p className="mb-0">
//             Whenever you need to, be sure to use margin utilities to keep things
//             nice and tidy.
//           </p>
//         </div>
//       </Alert>
//       <Alert variant="primary" className="alert-outline" dismissible>
//         <div className="alert-message">
//           <h4 className="alert-heading">Well done!</h4>
//           <p>
//             Aww yeah, you successfully read this important alert message. This
//             example text is going to run a bit longer so that you can see how
//             spacing within an alert works with this kind of content.
//           </p>
//           <hr />
//           <p className="mb-0">
//             Whenever you need to, be sure to use margin utilities to keep things
//             nice and tidy.
//           </p>
//         </div>
//       </Alert>
//     </Card.Body>
//   </Card>
// );

export const ShowConfirm = ({ title, description, action, onClose, param, textConfirm = "Confirmar", textCancel = "Cancelar" }) => (
  <Alert variant="primary" className="alert-outline">
    <div className="alert-message">
      <h4 className="alert-heading">{title}</h4>
      <p>{description}</p>
      <hr />
      <div className="btn-list text-end">
        <Button
          variant="success"
          className="me-1"
          onClick={() => {
            action(param);
            onClose();
          }}
        >
          {textConfirm}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          {textCancel}
        </Button>
      </div>
    </div>
  </Alert>
);
