import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { isValidToken, setSession, verify, sign } from "../utils/jwt";

const JWT_SECRET = process.env.REACT_APP_JWT
const JWT_EXPIRES_IN = "7d";
const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user_id: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user_id: action.payload.user_id,
        email: action.payload.email,
        nome: action.payload.nome,
        tipo: action.payload.tipo,
        plataforma_aplicativos: action.payload.plataforma_aplicativos,
        plataforma_eventos: action.payload.plataforma_eventos,
        aplicativos_customizacao: action.payload.aplicativos_customizacao,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        user_id: action.payload.user_id,
        email: action.payload.email,
        nome: action.payload.nome,
        tipo: action.payload.tipo,
        plataforma_aplicativos: action.payload.plataforma_aplicativos,
        plataforma_eventos: action.payload.plataforma_eventos,
        aplicativos_customizacao: action.payload.aplicativos_customizacao,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: false,
        user_id: null,
        email: null,
        nome: null,
        tipo: null,
        plataforma_aplicativos: null,
        plataforma_eventos: null,
        aplicativos_customizacao: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken) === true) {
          setSession(accessToken);
          const data = verify(accessToken, JWT_SECRET);
          signIn(false, false, data.user ? data.user : data.user_id)
        } else {
          dispatch({
            type: INITIALIZE,
            payload: initialState, // Entire array as the payload
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: initialState, // Entire array as the payload
        });
      }
    };
    initialize();
  }, []);

  const signIn = async (email, password, user_id = 0) => {
    await axios
      .post(
        "/api/signIn",
        { user_id: user_id },
        {
          auth: {
            username: email,
            password: password,
          },
        }
      )
      .then(function (responseAxios) {
        const user = responseAxios.data.message;
        const accessToken = sign(
          {
            user_id: user.user_id,
          },
          JWT_SECRET,
          {
            expiresIn: JWT_EXPIRES_IN,
          }
        );
        setSession(accessToken, user);
        dispatch({
          type: SIGN_IN,
          payload: user,
        });
      })
      .catch(function (error) {
        console.log(error)
        throw error.response.data["message"];
      });
  };

  const signOut = async () => {
    setSession(null);
    window.location.replace("/");
  };
  
  const resetPassword = (email) => console.log(email);
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
