import React from "react";
import { Container, Spinner } from "react-bootstrap";

const Loader = () => (
  <Container fluid className="d-flex h-100 w-100">
    <Spinner className=" m-auto" animation="border" variant="primary" />
  </Container>
);

export default Loader;
