import React from "react";
import { Dropdown } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { domainConfig } from "../../config.js";

const NavbarUser = () => {
  const auth = useAuth();

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={domainConfig.imageServer + "/avatar.png"}
            className="avatar img-fluid rounded-circle me-1"
            alt="Foto"
          />
          <span className="text-dark">{auth.nome}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item>Ajuda</Dropdown.Item>
        <Dropdown.Item onClick={auth.signOut}>Sair</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
